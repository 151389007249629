<template>
  <div class="pump-nav ly-wrap">
    <div class="ly-center nav-wrap">
      <div class="back" @click="goWay('/')"></div>
      <div class="nav-box">
        <div :class="[selected==='1'?'on':'','nav-item']" @click="goWay('/water/view')">工艺</div>
        <div :class="[selected==='2'?'on':'','nav-item']" @click="goWay('/water/control')">排泥</div>
        <div :class="[selected==='3'?'on':'','nav-item']" @click="goWay('/water/real')">实时</div>
        <div :class="[selected==='4'?'on':'','nav-item']" @click="goWay('/water/history')">历史</div>
        <div :class="[selected==='5'?'on':'','nav-item']" @click="goWay('/water/record')">记录</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: String,
      default: '1'
    }
  },
  components: {},
  data() {
    return {}
  },
  created() {
  },
  methods: {
    goWay(path) {
      this.$router.push({path: path})
    },
  },
}
</script>

<style scoped lang="less">
.pump-nav {
  background-color: #DEEFFF;
  height: 0.88rem;
  box-shadow: 0 0.04rem 0.02rem 0 rgba(0, 0, 0, .1);
  margin-bottom: 0.04rem;

  .nav-wrap {
    position: relative;

    .nav-box {
      background: #DEEFFF;
      white-space: nowrap; /*文本不会换行，文本会在在同一行上继续*/
      overflow-y: auto; /*可滑动*/
    }

    /*自定义滚动条的伪对象选择器, CSS 可以隐藏滚动条*/

    .nav-box::-webkit-scrollbar {
      display: none;
    }

    .nav-item {
      width: auto;
      padding: 0 0.23rem;
      height: 0.88rem;
      line-height: 0.88rem;
      background: #DEEFFF;
      font-size: 0.36rem;
      color: #999999;
      display: inline-block; /*行内块元素*/
    }

    .on {
      color: #FFFFFF;
      background: #0088FF;
    }

    .back {
      width: 1rem;
      height: 0.8rem;
      display: inline-block;
      float: left;
      background: url("../../../assets/img/more-dk.png") no-repeat center;
      transform: rotate(180deg);
      background-size: 0.22rem;
    }
  }
}

</style>

